.rdrCalendarWrapper {
    font-family: Pretendard;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #000000;
    font-size: 12px;
}

.rdrMonthAndYearWrapper {
    align-items: center;
    height: 44px;
}

.rdrMonthAndYearPickers {
    display: flex;
    flex-direction: row-reverse;
}

.rdrMonthAndYearPickers select {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.6px;
    color: #121214;
    background: none;
    border: 0;
    padding: 0;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    pointer-events: none;
}

.rdrMonthPicker, .rdrYearPicker {
    margin: 0 4px;
}

.rdrYearPicker::after {
    content: '년';
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.6px;
    margin-left: -0.6px;
    color: #121214;
}

.rdrNextPrevButton {
    display: block;
    width: 32px;
    height: 32px;
    border: 1px solid #dadae4;
    border-radius: 4px;
    background: none;
}

.rdrNextPrevButton i {
    display: block;
    width: 24px;
    height: 24px;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
}

.rdrPprevButton i {
    background-image: url("/icons/react-date-range/calendar-left.svg");
    margin-right: 3px;
}

.rdrNextButton i {
    background-image: url("/icons/react-date-range/calendar-right.svg");
    margin-left: 4px;
}

.rdrMonth {
    width: 100%;
    padding: 0;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
}

.rdrWeekDay {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-align: center;
    height: 40px;
    color: #2c2c34;
    margin-bottom: 8px;
}

.rdrDay {
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-align: center;
    height: 40px;
    color: #2c2c34;
}

.rdrDay:focus {
    outline: 0;
}

.rdrDayNumber {
    outline: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdrDayToday .rdrDayNumber span {
    font-weight: 700;
}

.rdrDayToday .rdrDayNumber span:after {
}

.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
}

.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #ffffff;
    z-index: 2;
}

.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #2c2c34;
    z-index: -1;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
    content: '';
    border: 1px solid #dadae4;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 28px;
    background: transparent;
}

.rdrDayPassive {
    pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
    color: #c8c8d2;
}

.rdrDayDisabled .rdrDayNumber span {
    color: #c8c8d2;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
    filter: grayscale(100%) opacity(60%);
}
