@font-face {
  font-family: 'HGGGothicssi';
  font-weight: 400;
  src: url(./HGGGothicssi400_subset.otf) format('opentype');
}
@font-face {
  font-family: 'HGGGothicssi';
  font-weight: 500;
  src: url(./HGGGothicssi500_subset.otf) format('opentype');
}
@font-face {
  font-family: 'HGGGothicssi';
  font-weight: 600;
  src: url(./HGGGothicssi600_subset.otf) format('opentype');
}
@font-face {
  font-family: 'HGGGothicssi';
  font-weight: 700;
  src: url(./HGGGothicssi700_subset.otf) format('opentype');
}
@font-face {
  font-family: 'neodgm';
  font-weight: 400;
  src: url(./neodgm.woff) format('woff'), url(./neodgm.ttf) format('truetype');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src: url(./Pretendard-Regular.woff) format('woff'),
    url(./Pretendard-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  src: url(./Pretendard-Medium.woff) format('woff'),
  url(./Pretendard-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  src: url(./Pretendard-SemiBold.woff) format('woff'),
    url(./Pretendard-SemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'establish';
  font-weight: 600;
  src: url(./establish-Retrosans.woff) format('woff'),
  url(./establish-Retrosans.ttf) format('truetype');
}
