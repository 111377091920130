:root {
  --toastify-color-light: rgba(0, 0, 0, 0.7);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-toast-width: fit-content;
  --toastify-toast-background: rgba(0, 0, 0, 0.7);
  --toastify-toast-min-height: 36px;
  --toastify-toast-max-height: 320px;
  --toastify-font-family: 'Pretendard', 'Apple SD Gothic Neo', Arial, Helvetica,
    sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #fff;
  --safe-area-inset-top: 0px;
  --safe-area-inset-bottom: 0px;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  max-width: 480px;
}

.Toastify__toast-container--bottom-center {
  bottom: calc(56px + var(--safe-area-inset-bottom));
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(49, 64, 75, 0.12),
    0 6px 16px rgba(52, 78, 97, 0.08), 0 9px 28px 8px rgba(103, 132, 153, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  /* webkit only issue #791 */
  z-index: 0;
}

.Toastify__toast-container--bottom-center .Toastify__toast {
  padding: 8px 20px;
}

.Toastify__toast-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.toast-content {
  text-align: center;
  white-space: nowrap;
  word-break: keep-all;
}

.Toastify__toast-icon {
  display: none;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.5s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__close-button {
  display: none;
}

@keyframes Toastify__fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes Toastify__fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__fadeIn;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__fadeOut;
}

/*# sourceMappingURL=ReactToastify.css.map */
